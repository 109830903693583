import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { CustomersUpdate } from '../sections/Tell/CustomersUpdate';
import { DownloadMarketingMaterials } from '../sections/Tell/Download';
import { TellHero } from '../sections/Tell/TellHero';
import {SEO} from "../components/Seo/Seo";

export const TellCustomers = () => {
	return (
		<MainLayout>
			<ScPage>
				<TellHero isCustomers={true} />
				<CustomersUpdate />
				<DownloadMarketingMaterials isCustomer />
			</ScPage>
		</MainLayout>
	);
};

export default TellCustomers;

export const Head = () => (
	<SEO
		title="Tell Customers About Gogeta | Save Money & Low 3% Commission"
		description="Let your customers know you're a Gogeta Bike partner. Help them save on bikes while your shop benefits from our low 3% commission. Spread the word today!"
	/>
)
